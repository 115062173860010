<template>
    <v-row style="color: rgb(26, 25, 25)!important">
      <v-col
        cols="12"
        class="text-center pb-0 mt-0"
      >
        <router-link :to="'/savings/clients/profile/account-settings/'+campaign.user_id">
            <h2 class="font-weight-bold text-left"><u>Client: {{campaign.username}} - {{ campaign.serial_no }}</u></h2>
        </router-link>
      </v-col>
      <v-col
        cols="12"
        class="text-right pb-0 mt-0"
        >
        <v-btn
            x-small
            elevation="10"
            @click="setDialog = true"
            class="text-xs font-weight-semibold"
            color="error darken-2"
        >
            <font-awesome-icon
            icon="fa-solid fa-minus"
            class="pe-1"
            />
            Delete Campaign
        </v-btn>
        <v-dialog width="500px" v-model="setDialog"> 
            <v-card> 
                <v-card-title class=" white--text">Delete Campaign</v-card-title> 
                <v-card-text class="black--text pt-2"> Are you sure you want to delete this campaign, a notification will be sent to the client and your activity will be logged.
                </v-card-text> 
                <v-spacer></v-spacer> 
                <v-card-actions class="text-right"> 
                    <v-btn color="error" @click="deleteCampaign()" > Delete </v-btn> 
                    <v-btn color="error" text @click="setDialog = false" > Close </v-btn> 
                </v-card-actions> 
            </v-card> 
            </v-dialog> 
      </v-col>

      <v-col cols="12" style="color: rgb(26, 25, 25)!important">
            <v-card class="mt-1" >
                <v-card-text v-if="campaign.target_amount && campaign.target_date" class="pa-2" style="font-size: 12px; ">
                    <h3 class="font-weight-bold text-left">{{campaign.name}}</h3>
                    <span class="dark--text text-left subheading"><b>Amount Saved:</b> 
                        <span class="pa-0 ma-1 dark--text text-left body-2"> {{campaign.saved_amount}} FCFA in {{campaign.passed_days}} days</span>
                    </span>
                </v-card-text>
                <v-card-text v-else-if="campaign.target_amount" class="pa-2" style="font-size: 12px; ">
                    <h3 class="font-weight-bold text-left">{{campaign.name}}</h3>
                    <span class="dark--text text-left subheading"><b>Amount Saved:</b> 
                        <span class="pa-0 ma-1 dark--text text-left body-2"> {{campaign.saved_amount}} FCFA </span>
                    </span>
                </v-card-text>
                <v-card-text v-else-if="campaign.target_date" class="pa-2" style="font-size: 12px; ">
                    <h3 class="font-weight-bold text-left">{{campaign.name}}</h3>
                    <span class="dark--text text-left subheading"><b>Amount Saved:</b> 
                        <span class="pa-0 ma-1 dark--text text-left body-2"> {{campaign.saved_amount}} FCFA </span>
                    </span>
                </v-card-text>
                <v-card-text v-else class="pa-2" style="font-size: 12px; ">
                    <h3 class="font-weight-bold text-left">{{campaign.name}}</h3>
                    <span class="dark--text text-left subheading"><b>Amount Saved:</b> 
                        <span class="dark--text text-left body-2"> {{campaign.saved_amount}} FCFA </span>
                    </span>
                </v-card-text>

                <v-card-text class="body-2 pt-0 px-2 pb-2" style="font-size: 12px; padding: 5px">
                    <span><b>Creation Date:</b> <span class="caption"> {{campaign.get_date_str}}</span></span>
                    <v-spacer></v-spacer>
                    <div v-if="campaign.target_amount && campaign.target_date" >
                        <span><b>Objective:</b> <span class="caption"> Reach {{campaign.target_amount}} F CFA By the {{campaign.target_date}} </span> </span>
                        <v-spacer></v-spacer>
                        <span><b>Remaining:</b> <span class="caption"> {{campaign.remaining_days}} days left</span></span>
                    </div>
                    <div v-else-if="campaign.target_amount" >
                        <span><b>Objective:</b> <span class="caption"> Reach {{campaign.target_amount}} F CFA </span> </span>
                        <v-spacer></v-spacer>
                        <span><b>Remaining:</b> <span class="caption"> {{campaign.remaining_days}} F CFA</span></span>
                    </div>
                    <div v-else-if="campaign.target_date" >
                        <span><b>Objective:</b> <span class="caption"> On the {{campaign.target_date}} </span> </span>
                        <v-spacer></v-spacer>
                        <span><b>Remaining:</b> <span class="caption"> {{campaign.remaining_days}} days left</span></span>
                    </div>
                    <v-spacer></v-spacer>
                    <span><b>Percentage Complete: </b> <span class="caption"> {{campaign.percentage_completed}}</span></span>
                    <v-spacer></v-spacer>
                    <span><b>Description: </b> <span class="caption"> {{campaign.description}}</span></span>
                </v-card-text>
            </v-card>

            <h2 v-if="campaignRequestedTransactions.length !== 0" class="mt-3 dark--text font-weight-bold text-left"><u>Requested Transactions</u></h2>
            <div v-if="campaignRequestedTransactions.length !== 0" class="white elevation-6 pa-2" style="max-height: 50px; overflow: auto; border-radius: 7px"> 
                <div color="white" style="" v-for="item in campaignRequestedTransactions" :key="item.id">
                    <v-card-text class="pa-1" style="font-size: 12px;">
                        <v-row v-if="!item.cancelled || item.withdrawal || (!item.withdrawal && !item.confirmed)">
                            <v-col cols="8" class="text-left">
                                <div v-if="item.withdrawal" > <!-- this will be an interrupt-->
                                    <span class="red--text font-weight-bold">Interrupt:</span><span> {{item.amount}} FCFA</span>
                                </div>
                                <div v-else>
                                    <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                                </div>
                                <div v-if="!item.confirmed && !item.cancelled" xs8 text-left>
                                    <span class="font-weight-bold ">Status:</span><span class="primary--text font-weight-bold "> Pending </span>
                                </div>
                                <div v-if="item.confirmed" xs8 text-left>
                                    <span class="font-weight-bold ">Status:</span><span class="success--text font-weight-bold "> Confirmed </span>
                                </div>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_date_str}}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                </div>
            </div>

            <h2 class="mt-3 dark--text font-weight-bold text-left"><u>Validated Transactions</u></h2>
            <div class="white elevation-6 pa-2" style="max-height: 300px; overflow: auto; border-radius: 7px"> 
                <div color="white" style="" v-for="item in campaignTransactions" :key="item.id">
                    <v-card-text class="pa-1" style="font-size: 12px;">
                        <v-row>
                            <v-col cols="6" class="pb-1 mb-0 text-left">
                                <div v-if="item.withdrawal" >
                                    <span class="dark--text font-weight-bold">Tranfered:</span><span> {{item.amount}} FCFA</span>
                                </div>
                                <div v-else>
                                    <span class="dark--text font-weight-bold">Deposit:</span><span> {{item.amount}} FCFA</span>
                                </div>
                            </v-col>
                            <v-col cols="6" class="pb-1 mb-0 text-right">
                                <span class="dark--text font-weight-bold">Date:</span><span> {{item.get_day_month_year_str}}</span>
                            </v-col>
                            <v-col cols="8" class="pt-1 mt-0 text-left">
                                <span class="dark--text font-weight-bold ">By:</span><span> {{item.top_up_admin_username}} - {{item.top_up_admin_phone_number}}</span>
                            </v-col>
                            <v-col cols="4" class="pt-1 mt-0 text-right">
                                <span class="dark--text font-weight-bold ">at</span><span> {{item.transaction_time}}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                <v-divider></v-divider>
                </div>
            </div>
      </v-col> 
    </v-row>
</template>

<script>
    import axios from 'axios'
    import 'animate.css'

    export default {
        data() {
            return {
                setDialog: false,
                campaign: [],
                campaignTransactions: [],
                campaignRequestedTransactions: [],
                interrupt_fees: '',
                blink: true,
            }
        },
        
        mounted(){
            // executes these after the page has been mounted
            this.getCampaignDetail();
            this.getCampaignTransactions();
            this.getCampaignRequestedTransactions();
            document.title = "PettyCash: Manager | Campaign Detail Info."
        },
        
        methods:{
            async getCampaignDetail(){                
                const campaign_id = this.$route.params.id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/manager/get/user/campaign/detail/'+campaign_id+'/')
                    .then(response => {
                        this.campaign = response.data  // get the data and fill into campaigns
                        console.log(this.campaign)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
            
            async getCampaignTransactions(){
                const campaign_id = this.$route.params.id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/manager/get/user/campaign/'+campaign_id+'/transactions/')
                    .then(response => {
                        this.campaignTransactions = response.data  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },

            async deleteCampaign(){
                const campaign_id = this.$route.params.id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/manager/delete/user/campaign/'+campaign_id+'/')
                    .then(response => {
                        this.$store.commit('setSnackBarMessage', response.data[0].text)
                        this.$store.commit('setSnackBarColor', "primary darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 6seconds and remember too reset after 
                        this.setDialog = false

                        if(response.data[0].category === "success"){
                            let router = this.$router
                            let user_id = this.campaign.user_id
                            setTimeout(function(){
                                router.push('/savings/clients/profile/account-settings/'+user_id)
                            }, 4000)
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
            async getCampaignRequestedTransactions(){
                const campaign_id = this.$route.params.id  // get campaign category from url and send request to server
                await axios
                    .get('/api/v1/manager/get/user/campaign/'+campaign_id+'/requested_transactions/')
                    .then(response => {
                        this.campaignRequestedTransactions = response.data  // get the data and fill into campaigns
                        console.log(this.campaignRequestedTransactions)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
            },
        },

    }
</script>
